body {
  margin: 0;
  font-weight: 300;
  font-family: Gotham, Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h1 {
  font-weight: 500;
}

.h2 {
  font-weight: 500;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/GothamSSm-XLight.otf");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/GothamSSm-Light.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/GothamSSm-Book.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/GothamSSm-Medium.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/GothamSSm-Bold.otf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
